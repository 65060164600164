import {
  getCurrentUserSuccess,
  getCurrentUserNotificationsSuccess,
  patchCurrentUserFailure,
  patchCurrentUserNotificationsSuccess,
  patchCurrentUserSubscriptionsSuccess,
  patchCurrentUserSuccess
} from 'core/redux/actions/profileActions';
import { isSomeReduxAction } from 'utils/common/typeGuardsUtilsCommon';
import {
  isAnySubscriptionOnWhenNotificationsOff,
  isPrimaryAndSecondaryEmailsIsOn
} from 'core/redux/selectors/validationSelectors';
import { getReduxError } from 'utils/redux/commonUtilsRedux';
import {
  NOTIFICATIONS_INCONSISTENCY,
  NOTIFICATIONS_SUBSCRIPTIONS_INCONSISTENCY
} from 'core/constants/redux/errorMessagesReduxConstants';

import { AppDispatch, AppState } from 'core/redux/store';

type ActionCreatorsType = typeof getCurrentUserSuccess
  | typeof patchCurrentUserSuccess
  | typeof getCurrentUserNotificationsSuccess
  | typeof patchCurrentUserNotificationsSuccess
  | typeof patchCurrentUserSubscriptionsSuccess;

export const notificationsAndSubscriptionsValidator = ({ action, state, dispatch }: { action: unknown, state: AppState, dispatch: AppDispatch}) => {
  if (isSomeReduxAction<ActionCreatorsType, ReturnType<ActionCreatorsType>>(
    action,
    [
      getCurrentUserSuccess,
      getCurrentUserNotificationsSuccess,
      patchCurrentUserSuccess,
      patchCurrentUserNotificationsSuccess,
      patchCurrentUserSubscriptionsSuccess
    ]
  )) {
    if (isPrimaryAndSecondaryEmailsIsOn(state)) {
      dispatch(patchCurrentUserFailure(getReduxError(new Error(NOTIFICATIONS_INCONSISTENCY))));
    }
    
    if (isAnySubscriptionOnWhenNotificationsOff(state)) {
      dispatch(patchCurrentUserFailure(getReduxError(new Error(NOTIFICATIONS_SUBSCRIPTIONS_INCONSISTENCY))));
    }
  }
}
import React from 'react';

import i18n from 'core/i18n/dictionary-ru.json';

export type I18nType = typeof i18n;

export type AppContextType = {
  i18n: I18nType;
}

export const AppContext = React.createContext<AppContextType>({ i18n: null } as unknown as AppContextType);

import { KeycloakError } from 'keycloak-js';
import { ErrorWithMessage } from 'core/types/redux/commonTypesRedux';
import { CurrentUserDeleteData } from 'core/types/api-clients/profileTypesApiClients';
import { ActionCreator, UnknownAction } from '@reduxjs/toolkit';

export const isNotNullish = <T>(v: T | null | undefined): v is T => v != null;

export const isNotUndefined = <T>(v: T | undefined): v is T => v !== undefined;

export const isNotNullishObject = (v: unknown): v is object  => typeof v === 'object' && isNotNullish(v);

export const isKeycloakError = (err: unknown): err is KeycloakError =>
  isNotNullishObject(err) &&
  'error' in err &&
  'error_description' in err;

export const isErrorWithMessage = (err: unknown): err is ErrorWithMessage =>
  isNotNullishObject(err) &&
  'message' in err;

export const isOTPDataNotNullish = (deleteData: { otpChallengeID: string | null | undefined, otpCode: string | null | undefined }): deleteData is CurrentUserDeleteData =>
  isNotNullish(deleteData.otpChallengeID) && isNotNullish(deleteData.otpCode);

export const isSomeReduxAction = <T extends ActionCreator<UnknownAction>, V extends UnknownAction>(action: unknown, actionCreatorsArray: T[]): action is V =>
  isNotNullishObject(action) &&
  'type' in action &&
  actionCreatorsArray.some(actionCreator => actionCreator().type === action.type);
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

import { authReducer } from 'core/redux/reducers/authReducer';
import { OTPReducer } from 'core/redux/reducers/OTPReducer';
import { profileReducer } from 'core/redux/reducers/profileReducer';
import { settingsReducer } from 'core/redux/reducers/settingsReducer';
import { recaptchaReducer } from 'core/redux/reducers/recaptchaReducer';
import { stateValidator } from 'core/redux/middlewares/stateValidatorMiddlewares';

const reducer = {
  OTPReducer,
  authReducer,
  profileReducer,
  settingsReducer,
  recaptchaReducer,
};

export const store = configureStore({
  reducer,
  middleware: (gDM) => gDM().concat(stateValidator),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppGetState = typeof store.getState;
export type AppState = ReturnType<AppGetState>
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

import {
  getCreateChallengeFailureData,
  getCreateChallengeSuccessData,
  getValidateChallengeSuccessData
} from 'core/utils/redux/otpUtilsRedux';
import { ReduxError, ReduxRequestStatus } from 'core/types/redux/commonTypesRedux';
import { Delivery, ValidationChallengeStatus } from 'core/types/api-clients/otpTypesApiClients';

export interface OTPError {
  code: number;
  message: string;
}

export enum OTPChallengeType {
  email = 'email',
  phone = 'phone',
}

export enum OTPChallengeAction {
  delete = 'delete',
  verify = 'verify',
}

export interface OTPChallengeActionData {
  typeOfChallenge: OTPChallengeType | null;
  rateLimit: number;
  challenge: {
    data: CreateChallengeReduxData | null;
    error: ReduxError | null;
    status: ReduxRequestStatus;
  }
  validation: {
    data: ValidateChallengeReduxData | null;
    error: ReduxError | null;
    status: ReduxRequestStatus;
  }
}

export type OTPState = {
  [key in OTPChallengeAction]?: OTPChallengeActionData
}

export interface CreateChallengeReduxData {
  id: string;
  phone?: string;
  smsDelivery?: Delivery;
  email?: string;
  emailDelivery?: Delivery;
}

export interface ValidateChallengeReduxData {
  phoneCode?: string;
  phoneChallengeId?: string;
  emailCode?: string;
  emailChallengeId?: string;
  ipAddress?: string;
  status: ValidationChallengeStatus | null;
}

export interface CreateChallengeStartData {
  challengeType: OTPChallengeType,
  challengeAction: OTPChallengeAction,
}

export type CreateChallengeSuccessData = ReturnType<typeof getCreateChallengeSuccessData>;
export type ValidateChallengeSuccessData = ReturnType<typeof getValidateChallengeSuccessData>;

export type CreateChallengeFailureData = ReturnType<typeof getCreateChallengeFailureData>;

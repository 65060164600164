import React, { FC, useContext } from 'react';

import { AppContext } from 'core/context/appContext';

import {
  OneColumnPageGrid,
  Button, ButtonTypes, ButtonStatus,
  Logo,
  NotificationBlock,
} from '@jnj/smn-components-web';

import { ErrorPageProps } from './ErrorPage.models';

import './ErrorPage.scss';

export const ErrorPage: FC<ErrorPageProps> = (props) => {
  const {
    message,
    notificationBlockContent,
    buttonText,
    onClickButton
  } = props;
  const { i18n } = useContext(AppContext);

  return (
    <OneColumnPageGrid className='error-page' alignItems='center'>
      <Logo />
      <h1
        className='error-page__title'
        data-testid='error-page-title'
      >
        {i18n['error-page']['title']}
      </h1>
      <div className='error-page__messages-container'>
        <p
          className='error-page__message'
          data-testid='error-page-message'
        >
          {message || i18n['error-page']['message']}
        </p>
        <NotificationBlock
          wrapperClassName='error-page__notification-block'
          type={notificationBlockContent.type}
          bodyText={notificationBlockContent.message}
        />
      </div>
      <Button
        type={ButtonTypes.button}
        text={buttonText || i18n['error-page']['button']}
        status={ButtonStatus.primary}
        onClick={onClickButton}
        className='error-page__button'
        testId='error-page-action'
      />
    </OneColumnPageGrid>
  );
}

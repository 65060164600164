import { settingsApiClient } from 'core/api-clients/settingsApiClient';
import { getSettingsFailure, getSettingsStart, getSettingsSuccess } from 'core/redux/actions/settingsActions';
import { getReduxError } from 'utils/redux/commonUtilsRedux';

import { AppDispatch } from 'core/redux/store';

export const getSettings = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(getSettingsStart());
    
    const { data } = await  settingsApiClient.getSettings();
    
    dispatch(getSettingsSuccess(data))
  } catch (err) {
    dispatch(getSettingsFailure(getReduxError(err)));
    
    throw err;
  }
}
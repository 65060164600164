import { RecaptchaActions } from 'core/types/recaptcha/actionsTypesRecaptcha';

export interface CreateChallengeRequestData {
  email?: string;
  phone?: string;
  recaptchaResponse: string;
  recaptchaAction: RecaptchaActions;
}

export enum CreateChallengeStatus {
  success = 'success',
  failure = 'failure',
}

export interface Delivery {
  status: CreateChallengeStatus;
  error: string | null;
}

export interface CreateChallengePhoneResponseData {
  id: string;
  phone: string;
  smsDelivery: Delivery;
}

export interface CreateChallengeEmailResponseData {
  id: string;
  email: string;
  emailDelivery: Delivery;
}

export type CreateChallengeResponseData = CreateChallengePhoneResponseData | CreateChallengeEmailResponseData;

export interface ValidateChallengeRequestData {
  code: string;
}

export enum ValidationChallengeStatus {
  success = 'success',
  failure = 'failure',
}

export interface ValidateChallengeResponseData {
  id: number;
  status: ValidationChallengeStatus;
  challengeId: string;
  code: string;
  ipAddress: string;
  error?: string;
}

import { profileApiClient } from 'core/api-clients/profileApiClient';
import {
  deleteCurrentUserStart,
  deleteCurrentUserSuccess,
  deleteCurrentUserFailure,
  getCurrentUserFailure,
  getCurrentUserStart,
  getCurrentUserSuccess,
  patchCurrentUserFailure,
  patchCurrentUserStart,
  patchCurrentUserSuccess,
  updateCurrentUser,
  getProfileSupportSubjectsStart,
  getProfileSupportSubjectsSuccess,
  getProfileSupportSubjectsFailure,
  postProfileSupportRequestStart,
  postProfileSupportRequestSuccess,
  postProfileSupportRequestFailure,
  patchCurrentUserNotificationsStart,
  patchCurrentUserNotificationsSuccess,
  patchCurrentUserNotificationsFailure,
  patchCurrentUserSubscriptionsStart,
  patchCurrentUserSubscriptionsSuccess,
  patchCurrentUserSubscriptionsFailure,
  getCurrentUserNotificationsStart,
  getCurrentUserNotificationsSuccess,
  getCurrentUserNotificationsFailure,
} from 'core/redux/actions/profileActions';
import { getOtpDataByChallengeType } from 'core/redux/selectors/OTPSelectors';

import { withRefreshErrorHandling } from 'core/redux/decorators/withRefreshErrorHandling';
import { withRepeatRequest } from 'core/redux/decorators/withRepeatRequest';
import { getReduxError } from 'utils/redux/commonUtilsRedux';
import { isOTPDataNotNullish } from 'utils/common/typeGuardsUtilsCommon';
import { DELETE_USER_ABSENT_OTP_ERROR, NOTIFICATIONS_RETRY_ERROR } from 'core/constants/redux/errorMessagesReduxConstants';
import {
  DEFAULT_NUMBER_OF_GET_NOTIFICATIONS_REPETITION,
  GET_NOTIFICATIONS_CALL_TIMEOUT
} from 'core/constants/redux/decoratorsReduxConstants';

import {
  CurrentUserNotificationsPatchData,
  CurrentUserPatchData,
  SupportRequestsPostData
} from 'core/types/api-clients/profileTypesApiClients';
import { AppDispatch, AppGetState } from 'core/redux/store';
import { OTPChallengeAction } from 'core/types/redux/otpTypesRedux';

export const getCurrentUser = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(getCurrentUserStart());
    
    const { data } = await profileApiClient.getCurrent();

    dispatch(getCurrentUserSuccess(data));
  } catch (err) {
      dispatch(getCurrentUserFailure(getReduxError(err)));
      
      throw err;
  }
};

export const patchCurrentUser = (patchData: CurrentUserPatchData) => async (dispatch: AppDispatch) => {
  try {
    dispatch(patchCurrentUserStart());
    
    await withRefreshErrorHandling({
      patchMethod: () => profileApiClient.patchCurrent(patchData),
      getMethod: () => profileApiClient.getCurrent(),
      successGetCallback: (responseData) => dispatch(updateCurrentUser(responseData))
    });
    
    dispatch(patchCurrentUserSuccess(patchData));
  } catch (err) {
    dispatch(patchCurrentUserFailure(getReduxError(err)));
    
    throw err;
  }
};

export const patchCurrentUserWithOTP = (verifyData: CurrentUserPatchData) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const otpData = getOtpDataByChallengeType(getState(), OTPChallengeAction.verify);
  
  await dispatch(patchCurrentUser({...otpData, ...verifyData }));
};

export const getCurrentUserNotifications = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(getCurrentUserNotificationsStart());
    
    const { data } = await withRepeatRequest({
      func: () => profileApiClient.getCurrentUserNotifications(),
      repeatCount: DEFAULT_NUMBER_OF_GET_NOTIFICATIONS_REPETITION,
      delay: GET_NOTIFICATIONS_CALL_TIMEOUT,
      errorMessage: NOTIFICATIONS_RETRY_ERROR
    });
    
    dispatch(getCurrentUserNotificationsSuccess(data));
  } catch (err) {
    dispatch(getCurrentUserNotificationsFailure(getReduxError(err)));
    
    throw err;
  }
};

export const patchCurrentUserNotifications = (patchData: CurrentUserNotificationsPatchData, name: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(patchCurrentUserNotificationsStart(name));
    
    const { data } = await withRefreshErrorHandling({
      patchMethod: () => profileApiClient.patchCurrentUserNotifications(patchData),
      getMethod: () => profileApiClient.getCurrent(),
      successGetCallback: (responseData) => dispatch(updateCurrentUser(responseData))
    });
    
    dispatch(patchCurrentUserNotificationsSuccess({ data, name }));
  } catch (err) {
    dispatch(patchCurrentUserNotificationsFailure({ error: getReduxError(err), name }));
    
    throw err;
  }
};

export const patchCurrentUserSubscriptions = (patchData: CurrentUserNotificationsPatchData, name: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(patchCurrentUserSubscriptionsStart(name));
    
    const { data } = await withRefreshErrorHandling({
      patchMethod: () => profileApiClient.patchCurrentUserNotifications(patchData),
      getMethod: () => profileApiClient.getCurrent(),
      successGetCallback: (responseData) => dispatch(updateCurrentUser(responseData))
    });
    
    dispatch(patchCurrentUserSubscriptionsSuccess({ data, name }));
  } catch (err) {
    dispatch(patchCurrentUserSubscriptionsFailure({ error: getReduxError(err), name }));
    
    throw err;
  }
};

export const deleteCurrentUser = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  try {
    dispatch(deleteCurrentUserStart());
    const deleteData = getOtpDataByChallengeType(getState(), OTPChallengeAction.delete);
    
    if (isOTPDataNotNullish(deleteData)) {
      await profileApiClient.deleteCurrent(deleteData);
      
      dispatch(deleteCurrentUserSuccess());
    } else {
      throw new Error(DELETE_USER_ABSENT_OTP_ERROR);
    }
  } catch (err) {
    dispatch(deleteCurrentUserFailure(getReduxError(err)));
    
    throw err;
  }
};

export const getProfileSupportSubjects = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(getProfileSupportSubjectsStart());

    const { data } = await profileApiClient.getSupportRequestSubjects();

    dispatch(getProfileSupportSubjectsSuccess(data));
  } catch (err) {
    dispatch(getProfileSupportSubjectsFailure(getReduxError(err)));

    throw err;
  }
};

export const postProfileSupportRequest = (postData: SupportRequestsPostData) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postProfileSupportRequestStart());

    const { data } = await profileApiClient.postSupportRequest(postData);

    dispatch(postProfileSupportRequestSuccess(data));
  } catch (err) {
    dispatch(postProfileSupportRequestFailure(getReduxError(err)));

    throw err;
  }
};

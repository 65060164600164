import { useLayoutEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'core/redux/store';
import { authenticateUser } from 'core/redux/thunks/authThunks';
import { getIsAuthStatusInit } from 'core/redux/selectors/authSelectors';
import { getAuthOptionsByUserType } from 'utils/auth/optionsUtilsAuth';

export const useAuthentication = () => {
  const dispatch = useAppDispatch();
  const isAuthStatusInit = useAppSelector(getIsAuthStatusInit);

  const authOptions = getAuthOptionsByUserType();

  useLayoutEffect(() => {
    if (isAuthStatusInit) {
      dispatch(authenticateUser(authOptions))
    }
  }, [dispatch, isAuthStatusInit, authOptions])
}
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'core/redux/store';
import { getRecaptcha } from 'core/redux/thunks/recaptchaThunks';
import { getIsRecaptchaStatusInit } from 'core/redux/selectors/recaptchaSelectors';

export const useGetRecaptcha = () => {
  const dispatch = useAppDispatch();

  const isRecaptchaStatusInit = useAppSelector(getIsRecaptchaStatusInit);
  
  useEffect(() => {
    if (isRecaptchaStatusInit) {
      dispatch(getRecaptcha());
    }
  }, [isRecaptchaStatusInit, dispatch]);
}
import { createReducer } from '@reduxjs/toolkit';

import { getRecaptchaFailure, getRecaptchaStart, getRecaptchaSuccess } from 'core/redux/actions/recaptchaActions';

import { RecaptchaState } from 'core/types/redux/recaptchaTypesRedux';
import { ReduxRequestStatus } from 'core/types/redux/commonTypesRedux';

const initialState: RecaptchaState = {
  recaptcha: {
    error: null,
    status: ReduxRequestStatus.init
  }
};

export const recaptchaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getRecaptchaStart, (state) => {
      state.recaptcha.status = ReduxRequestStatus.loading;
      state.recaptcha.error = null;
    })
    .addCase(getRecaptchaSuccess, (state, action) => {
      state.recaptcha.status = ReduxRequestStatus.success;
    })
    .addCase(getRecaptchaFailure, (state, action) => {
      state.recaptcha.error = action.payload;
      state.recaptcha.status = ReduxRequestStatus.error;
    })
})

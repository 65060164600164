import axios from 'axios';

import { withKeycloak } from 'core/api-clients/decorators/withKeycloak';
import { TIMEOUT_LIMIT } from 'core/constants/api-clients/commonConstantsApiClients';

import { ApiClientErrors } from 'core/types/api-clients/commonTypesApiClients';

export const apiClient = axios.create({
  timeout: TIMEOUT_LIMIT,
  timeoutErrorMessage: ApiClientErrors.timeout
});
export const keycloakApiClient = withKeycloak(apiClient);

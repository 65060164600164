export const TIMEOUT_LIMIT = 120000;
export const TIMEOUT_ERROR_CODE = 'ECONNABORTED';

export const SERVER_STATUS_CODE_ERROR = 500;
export const SERVER_RECAPTCHA_BROWSER_ERROR = 511;
export const CLIENT_STATUS_CODE_ERROR = 400;
export const TOO_MANY_REQUESTS_STATUS_CODE = 429;
export const AUTH_ERROR_CODE = 401;

export const applicationJsonContentType = { 'Content-Type': 'application/json'};
export const multipartFormDataContentType = { 'Content-Type': 'multipart/form-data'};
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'core/redux/store';
import {
  getCurrentUser,
  getCurrentUserNotifications,
  getProfileSupportSubjects
} from 'core/redux/thunks/profileThunks';
import { getSettings } from 'core/redux/thunks/settingsThunks';
import {
  getIsCurrentUserStatusInit,
  getIsSupportRequestSubjectsStatusInit,
  getIsCurrentUserNotificationsStatusInit,
  getIsCurrentUserStatusSuccess
} from 'core/redux/selectors/profileSelectors';
import { getIsAuthStatusSuccess } from 'core/redux/selectors/authSelectors';
import { getIsSettingsStatusInit } from 'core/redux/selectors/settingsSelectors';

export const useAppDataInit = () => {
  const dispatch = useAppDispatch();
  const isAuthSuccess = useAppSelector(getIsAuthStatusSuccess);
  const isUserStatusInit = useAppSelector(getIsCurrentUserStatusInit);
  const isUserStatusSuccess = useAppSelector(getIsCurrentUserStatusSuccess);
  const isSupportRequestSubjectsStatusInit = useAppSelector(getIsSupportRequestSubjectsStatusInit);
  const isSettingsStatusInit = useAppSelector(getIsSettingsStatusInit);
  const isUserNotificationsStatusInit = useAppSelector(getIsCurrentUserNotificationsStatusInit);
  
  
  useEffect(() => {
    if (isAuthSuccess && isSettingsStatusInit) {
      dispatch(getSettings());
    }
  }, [dispatch, isAuthSuccess, isSettingsStatusInit]);
  
  useEffect(() => {
    if (isAuthSuccess && isUserStatusInit) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, isAuthSuccess, isUserStatusInit]);

  useEffect(() => {
    if (isAuthSuccess && isSupportRequestSubjectsStatusInit) {
      dispatch(getProfileSupportSubjects());
    }
  }, [dispatch, isAuthSuccess, isSupportRequestSubjectsStatusInit]);
  
  useEffect(() => {
    if (isUserStatusSuccess && isUserNotificationsStatusInit) {
      dispatch(getCurrentUserNotifications());
    }
  }, [dispatch, isUserStatusSuccess, isUserNotificationsStatusInit]);
}
import { getKeycloakUrl } from 'utils/api-clients/commonUtilsApiClients';
import { ClientIds, Realms } from 'core/constants/auth/realms';

export const getAuthOptionsByUserType = () => {
  const authUrl = getKeycloakUrl();
  
  return {
    url: authUrl,
    realm: Realms.ECP_REALM,
    clientId: ClientIds.ECP_CLIENT_ID,
  }
}
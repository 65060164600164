import { AppState } from 'core/redux/store';
import { ReduxRequestStatus } from 'core/types/redux/commonTypesRedux';

export const getRecaptchaReducer = (state: AppState) => state.recaptchaReducer;

export const getRecaptchaRequestStatus = (state: AppState) => getRecaptchaReducer(state).recaptcha.status;

export const getRecaptchaError = (state: AppState) => getRecaptchaReducer(state).recaptcha.error;

export const getIsRecaptchaStatusInit = (state: AppState) => getRecaptchaRequestStatus(state) === ReduxRequestStatus.init;

export const getIsRecaptchaStatusLoading = (state: AppState) => getRecaptchaRequestStatus(state) === ReduxRequestStatus.loading;

export const getIsRecaptchaError = (state: AppState) => getRecaptchaRequestStatus(state) === ReduxRequestStatus.error;

export const getIsRecaptchaStatusInitOrLoading = (state: AppState) => getIsRecaptchaStatusInit(state) || getIsRecaptchaStatusLoading(state);

import { AxiosResponse } from 'axios';

import { apiClient } from 'core/api-clients/apiClient';
import { getSettingsAPIUrl } from 'utils/api-clients/commonUtilsApiClients';

import { GetSettingsResponseData } from 'core/types/api-clients/settingsTypesApiClients';

export const settingsApiUrl = getSettingsAPIUrl();

export const settingsApiClient = {
  getSettings (): Promise<AxiosResponse<GetSettingsResponseData>> {
    return apiClient.get(`${settingsApiUrl}/contact-validations`);
  },
}

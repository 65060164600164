import { RECAPTCHA_SITE_KEY } from 'core/constants/ui/recaptcha';
import { RecaptchaActions } from 'core/types/recaptcha/actionsTypesRecaptcha';
import { GET_RECAPTCHA_TOKEN_ERROR } from 'core/constants/redux/errorMessagesReduxConstants';

export const getRecaptchaToken = (
  recaptchaAction: RecaptchaActions,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      const recaptcha = window.grecaptcha?.enterprise;
      
      recaptcha?.ready(async () => {
        try {
          const recaptchaToken = await recaptcha.execute(RECAPTCHA_SITE_KEY, { action: recaptchaAction });
          resolve(recaptchaToken);
        } catch {
          reject(new Error(GET_RECAPTCHA_TOKEN_ERROR));
        }
      });
    } catch {
      reject(new Error(GET_RECAPTCHA_TOKEN_ERROR));
    }
  });
};

export enum OTPConstants {
  CREATE_CHALLENGE_INIT_LOADING = 'otp/createChallengeInitLoading',
  CREATE_CHALLENGE_LOADING = 'otp/createChallengeLoading',
  CREATE_CHALLENGE_SUCCESS = 'otp/createChallengeSuccess',
  CREATE_CHALLENGE_FAILURE = 'otp/createChallengeFailure',

  SET_CHALLENGE_TYPE = 'otp/setChallengeType',

  SET_RATE_LIMIT = 'otp/setRateLimit',
  
  RESET_CHALLENGE_STATUS = 'otp/resetChallengeStatus',

  VALIDATE_CHALLENGE_START = 'otp/validateChallengeStart',
  VALIDATE_CHALLENGE_SUCCESS = 'otp/validateChallengeSuccess',
  VALIDATE_CHALLENGE_FAILURE = 'otp/validateChallengeFailure',
}

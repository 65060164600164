import { ReduxRequestStatus, ReduxError } from 'core/types/redux/commonTypesRedux';

export interface AuthState {
  status: ReduxRequestStatus,
  error: ReduxError | null,
}

export enum AuthErrorMessages {
  refreshToken = 'Ошибка при обновлении токена.',
  getTokenOrCode = 'Ошибка при получении кода или токена.'
}
import { FC } from 'react';
import {
  SnackbarProvider as SnackbarProviderBase,
  SnackbarProviderProps,
} from 'notistack';

import { SNACKBAR_HIDE_TIME, SNACKBAR_MAX_SNACK } from 'core/constants/ui/snackbar';

export const SnackbarProvider: FC<SnackbarProviderProps> = (props) => {
  const { children } = props;

  return (
    <SnackbarProviderBase
      autoHideDuration={SNACKBAR_HIDE_TIME}
      maxSnack={SNACKBAR_MAX_SNACK}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
    >
      {children}
    </SnackbarProviderBase>
  );
};

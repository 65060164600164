import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { lazy } from 'react';

import { BootstrapApplicationData } from 'components/bootstrap-application-data';

const ProfilePage = lazy(() => import('pages/profile-page'));

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <BootstrapApplicationData>
        <Routes>
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='*' element={<Navigate to={'profile'}/>} />
        </Routes>
      </BootstrapApplicationData>
    </BrowserRouter>
  )
}
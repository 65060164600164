import { createReducer } from '@reduxjs/toolkit';
import { authUserFailure, authUserStart, authUserSuccess } from 'core/redux/actions/authActions';
import { ReduxRequestStatus } from 'core/types/redux/commonTypesRedux';
import { AuthState } from 'core/types/redux/authTypesRedux';

const initialState: AuthState = {
  status: ReduxRequestStatus.init,
  error: null,
};

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(authUserStart, (state) => {
      state.status = ReduxRequestStatus.loading;
    })
    .addCase(authUserSuccess, (state) => {
      state.status = ReduxRequestStatus.success;
    })
    .addCase(authUserFailure, (state, action) => {
      state.status = ReduxRequestStatus.error;
      state.error = action.payload;
    })
});
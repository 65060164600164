export enum Realms {
  ECP_REALM = 'ecp'
}

export enum ClientIds {
  ECP_CLIENT_ID = 'user-profile-ecp-frontend',
}

export enum OnLoadAuthParam {
  LOGIN_REQUIRED = 'login-required',
}

export enum PkceAuthMethod {
  S256 = 'S256'
}

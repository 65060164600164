import { createRoot } from 'react-dom/client';
import { StrictMode, Suspense } from 'react';
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material';

import { AppContext } from 'core/context/appContext';
import i18n from 'core/i18n/dictionary-ru.json';
import { SnackbarProvider } from 'components/snackbar-provider';

import { store } from 'core/redux/store';
import { AppRoutes } from 'core/router/setup';
import { theme } from 'core/material-ui/setup';

import './app/assets/css/main.scss';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppContext.Provider value={{ i18n }}>
            <SnackbarProvider>
              <Suspense>
                <AppRoutes />
              </Suspense>
            </SnackbarProvider>
          </AppContext.Provider>
        </ThemeProvider>
      </Provider>
  </StrictMode>
);

export const DELETE_USER_ABSENT_OTP_ERROR = 'DELETE_USER_ABSENT_OTP_ERROR';

export const REFRESH_DATA_SUCCESS = 'REFRESH_DATA_SUCCESS';
export const REFRESH_DATA_ERROR = 'REFRESH_DATA_ERROR';

export const RECAPTCHA_TOKEN_IS_ABSENT = 'RECAPTCHA_TOKEN_IS_ABSENT';
export const NO_RESPONSE_WHEN_RECAPTCHA_REQUESTED = 'NO_RESPONSE_WHEN_RECAPTCHA_REQUESTED';
export const GET_RECAPTCHA_TOKEN_ERROR = 'GET_RECAPTCHA_TOKEN_ERROR';

export const NOTIFICATIONS_INCONSISTENCY = 'NOTIFICATION_INCONSISTENCY';
export const NOTIFICATIONS_SUBSCRIPTIONS_INCONSISTENCY = 'NOTIFICATIONS_SUBSCRIPTIONS_INCONSISTENCY';
export const NOTIFICATIONS_RETRY_ERROR = 'NOTIFICATIONS_RETRY_ERROR'
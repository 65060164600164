import { AppState } from 'core/redux/store';
import { ReduxRequestStatus } from 'core/types/redux/commonTypesRedux';
import { CreateChallengeStatus, ValidationChallengeStatus } from 'core/types/api-clients/otpTypesApiClients';
import { OTPChallengeAction, OTPChallengeType } from 'core/types/redux/otpTypesRedux';
import { createSelector } from '@reduxjs/toolkit';

export const getOTPReducer = (state: AppState) => state.OTPReducer;

export const getChallengeStatus = (state: AppState) => (challengeAction: OTPChallengeAction) => getOTPReducer(state)[challengeAction]?.challenge.status;

export const getValidationChallengeStatus = (state: AppState) => (challengeAction: OTPChallengeAction) => getOTPReducer(state)[challengeAction]?.validation.status;

export const getChallengeData = (state: AppState) => (challengeAction: OTPChallengeAction) => getOTPReducer(state)[challengeAction]?.challenge.data;

export const getValidationChallengeData = (state: AppState) => (challengeAction: OTPChallengeAction) =>  getOTPReducer(state)[challengeAction]?.validation.data;

export const getChallengeError = (state: AppState) => (challengeAction: OTPChallengeAction) => getOTPReducer(state)[challengeAction]?.challenge.error;

export const getValidationChallengeError = (state: AppState) => (challengeAction: OTPChallengeAction) => getOTPReducer(state)[challengeAction]?.validation.error;

export const getChallengeType = (state: AppState) => (challengeAction: OTPChallengeAction) => getOTPReducer(state)[challengeAction]?.typeOfChallenge;

export const getIsCreateChallengeInit = (state: AppState) => (challengeAction: OTPChallengeAction) =>  {
  const status = getChallengeStatus(state)(challengeAction);
  return status === undefined || status === ReduxRequestStatus.init;
}

export const getIsCreateChallengeInitLoading = (state: AppState) => (challengeAction: OTPChallengeAction) =>  getChallengeStatus(state)(challengeAction) === ReduxRequestStatus.initLoading;

export const getIsCreateChallengeLoading = (state: AppState) => (challengeAction: OTPChallengeAction) => getChallengeStatus(state)(challengeAction) === ReduxRequestStatus.loading;

export const getIsValidateChallengeLoading = (state: AppState) => (challengeAction: OTPChallengeAction) => getValidationChallengeStatus(state)(challengeAction) === ReduxRequestStatus.loading;

export const getChallengeRateLimit = (state: AppState) => (challengeAction: OTPChallengeAction) => getOTPReducer(state)[challengeAction]?.rateLimit ?? 0;

export const getIsValidateChallengeSuccess = (state: AppState) => (challengeAction: OTPChallengeAction) => getValidationChallengeData(state)(challengeAction)?.status === ValidationChallengeStatus.success;

export const getIsValidateChallengeError = (state: AppState) => (challengeAction: OTPChallengeAction) => getValidationChallengeData(state)(challengeAction)?.status === ValidationChallengeStatus.failure;

export const getIsCreateChallengeSmsDeliveryError = (state: AppState) => (challengeAction: OTPChallengeAction) => getChallengeData(state)(challengeAction)?.smsDelivery?.status === CreateChallengeStatus.failure;

export const getIsCreateChallengeEmailDeliveryError = (state: AppState) => (challengeAction: OTPChallengeAction) => getChallengeData(state)(challengeAction)?.emailDelivery?.status === CreateChallengeStatus.failure;

export const getIsPhoneChallenge = (state: AppState) => (challengeAction: OTPChallengeAction) => getChallengeType(state)(challengeAction) === OTPChallengeType.phone;

export const getOtpDataByChallengeType = createSelector(
  getChallengeType,
  getValidationChallengeData,
  (state: AppState, challengeAction: OTPChallengeAction) => challengeAction,
  (challengeTypeFn, validationChallengeDataFn, challengeAction) => {
    const validationChallengeData = validationChallengeDataFn(challengeAction);
    return challengeTypeFn(challengeAction) === OTPChallengeType.phone
      ? { otpChallengeID: validationChallengeData?.phoneChallengeId, otpCode: validationChallengeData?.phoneCode }
      : { otpChallengeID: validationChallengeData?.emailChallengeId, otpCode: validationChallengeData?.emailCode }
  }
);


import { reloadLocation } from 'utils/browser/locationUtilsBrowser';

import { NotificationBlockType } from '@jnj/smn-components-web';
import { I18nType } from 'core/context/appContext';
import { ReduxError } from 'core/types/redux/commonTypesRedux';

export const getErrorContentByErrorType = ({
    i18n,
    isAuthError,
    authError,
    isUserDataInitError,
    userError,
    isSupportRequestSubjectsStatusError,
    supportRequestSubjectsError,
    isSettingsStatusError,
    settingsError,
    isRecaptchaError,
    recaptchaError,
  }: {
  i18n: I18nType,
  isAuthError: boolean,
  authError: ReduxError | null,
  isUserDataInitError: boolean,
  userError: ReduxError | null,
  isSupportRequestSubjectsStatusError: boolean,
  supportRequestSubjectsError: ReduxError | null,
  isSettingsStatusError: boolean,
  settingsError: ReduxError | null,
  isRecaptchaError: boolean,
  recaptchaError: ReduxError | null,
}) => {
  const content = {
    errorMessage: null,
    notificationType: NotificationBlockType.error,
    notificationMessage: i18n['bootstrap-application-data']['default-notification-message'],
    errorButtonText: i18n['bootstrap-application-data']['primary-button-text'],
    onClickFn: reloadLocation,
  }
  
  if (isAuthError) {
    return {
      ...content,
      errorMessage: i18n['bootstrap-application-data']['auth-error-message'],
      notificationMessage: authError?.message,
    }
  }
  
  if (isUserDataInitError || isSupportRequestSubjectsStatusError || isSettingsStatusError) {
    return {
      ...content,
      errorMessage: i18n['bootstrap-application-data']['loading-data-error-message'],
      notificationMessage: userError?.message || supportRequestSubjectsError?.message || settingsError?.message,
    }
  }
  
  if (isRecaptchaError) {
    return {
      ...content,
      errorMessage: i18n.recaptcha['common-error'],
      notificationMessage: recaptchaError?.message,
    }
  }
}
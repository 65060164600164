import {
  notificationsAndSubscriptionsValidator,
} from 'core/redux/validators/profileValidators';

import {
  Middleware
} from '@reduxjs/toolkit';

export const stateValidator: Middleware = ({ getState, dispatch }) => (next: (action: unknown) => unknown) => (action: unknown) => {
  const response = next(action);
  const nextState = getState();
  
  notificationsAndSubscriptionsValidator({ action, dispatch, state: nextState });

  return response;
}
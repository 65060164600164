import { createReducer } from '@reduxjs/toolkit';

import {
  getSettingsStart, getSettingsSuccess, getSettingsFailure,
} from 'core/redux/actions/settingsActions';

import { SettingsState } from 'core/types/redux/settingsTypesRedux';
import { ReduxRequestStatus } from 'core/types/redux/commonTypesRedux';

const initialState: SettingsState = {
  data: null,
  status: ReduxRequestStatus.init,
  error: null,
};

export const settingsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSettingsStart, (state) => {
      state.status = ReduxRequestStatus.loading;
      state.data = null;
      state.error = null;
    })
    .addCase(getSettingsSuccess, (state, action) => {
      state.data = action.payload;
      state.status = ReduxRequestStatus.success;
    })
    .addCase(getSettingsFailure, (state, action) => {
      state.error = action.payload;
      state.status = ReduxRequestStatus.error;
    })
})

import { AppState } from 'core/redux/store';
import { ReduxRequestStatus } from 'core/types/redux/commonTypesRedux';

export const getAuthReducer = (state: AppState) => state.authReducer;

export const getAuthStatus = (state: AppState) => getAuthReducer(state).status;

export const getIsAuthStatusInit = (state: AppState) => getAuthStatus(state) === ReduxRequestStatus.init;

export const getIsAuthStatusLoading = (state:AppState) => getAuthStatus(state) === ReduxRequestStatus.loading;

export const getIsAuthStatusError = (state: AppState) => getAuthStatus(state) === ReduxRequestStatus.error;

export const getIsAuthStatusSuccess = (state: AppState) => getAuthStatus(state) === ReduxRequestStatus.success;

export const getIsAuthInitOrLoading = (state: AppState) => getIsAuthStatusInit(state) || getIsAuthStatusLoading(state);

export const getAuthError = (state: AppState) => getAuthReducer(state).error;
import { createSelector } from '@reduxjs/toolkit';
import { getContactInfo, getSubscriptions } from 'core/redux/selectors/profileSelectors';

export const isPrimaryAndSecondaryEmailsIsOn = createSelector(
  getContactInfo,
  contactInfo => contactInfo.secondaryEmailNotificationsOptIn && contactInfo.emailNotificationsOptIn
);

export const isAnySubscriptionOnWhenNotificationsOff = createSelector(
  getContactInfo,
  getSubscriptions,
  (contactInfo, subscriptions) => !contactInfo.secondaryEmailNotificationsOptIn
    && !contactInfo.emailNotificationsOptIn
    && !contactInfo.phoneNotificationsOptIn
    && subscriptions?.length,
)
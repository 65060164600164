import { createReducer } from '@reduxjs/toolkit';

import {
  createChallengeFailure,
  createChallengeInitLoading,
  createChallengeLoading,
  createChallengeSuccess,
  resetChallengeStatus,
  validateChallengeFailure,
  validateChallengeStart,
  validateChallengeSuccess,
} from 'core/redux/actions/OTPActions';

import { OTPState } from 'core/types/redux/otpTypesRedux';
import { ReduxRequestStatus } from 'core/types/redux/commonTypesRedux';

const initialState: OTPState = {};

export const OTPReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createChallengeInitLoading, (state, action) => {
      const { payload: { challengeAction, challengeType} } = action;
      state[challengeAction] = {
        typeOfChallenge: challengeType,
        rateLimit: 0,
        challenge: {
          data: null,
          error: null,
          status: ReduxRequestStatus.initLoading,
        },
        validation: {
          data: null,
          error: null,
          status: ReduxRequestStatus.init,
        }
      };
    })
    .addCase(createChallengeLoading, (state, action) => {
      const { payload: { challengeAction, challengeType} } = action;
      const challengeActionState = state[challengeAction];
      if (challengeActionState) {
        challengeActionState.typeOfChallenge = challengeType;
        challengeActionState.challenge.data = null;
        challengeActionState.challenge.error = null;
        challengeActionState.rateLimit = 0;
        challengeActionState.challenge.status = ReduxRequestStatus.loading;
      }
    })
    .addCase(createChallengeSuccess, (state, action) => {
      const { payload: { challengeAction, data, rateLimit} } = action;
      const challengeActionState = state[challengeAction];
      if (challengeActionState) {
        challengeActionState.rateLimit = rateLimit;
        challengeActionState.challenge.data = data;
        challengeActionState.challenge.status = ReduxRequestStatus.success;
      }
    })
    .addCase(createChallengeFailure, (state, action) => {
      const { payload: { challengeAction, error, rateLimit} } = action;
      const challengeActionState = state[challengeAction];
      if (challengeActionState) {
        challengeActionState.challenge.error = error;
        challengeActionState.challenge.status = ReduxRequestStatus.error;
        
        if (rateLimit) {
          challengeActionState.rateLimit = rateLimit;
        }
      }
    })
    .addCase(validateChallengeStart, (state, action) => {
      const { payload: challengeAction } = action;
      const challengeActionState = state[challengeAction];
      if (challengeActionState) {
        challengeActionState.validation.error = null;
        challengeActionState.validation.status = ReduxRequestStatus.loading;
        if (challengeActionState.validation.data) {
          challengeActionState.validation.data.status = null;
        }
      }
    })
    .addCase(validateChallengeSuccess, (state, action) => {
      const { payload: { challengeAction, data } } = action;
      const challengeActionState = state[challengeAction];
      if (challengeActionState) {
        challengeActionState.validation.data = { ...challengeActionState.validation.data, ...data };
        challengeActionState.validation.status = ReduxRequestStatus.success;
      }
    })
    .addCase(validateChallengeFailure, (state, action) => {
      const { payload: { challengeAction, err } } = action;
      const challengeActionState = state[challengeAction];
      if (challengeActionState) {
        challengeActionState.validation.error = err;
        challengeActionState.validation.status = ReduxRequestStatus.error;
      }
    })
    .addCase(resetChallengeStatus, (state, action) => {
      const { payload: challengeAction } = action;
      const challengeActionState = state[challengeAction]
      if (challengeActionState) {
        challengeActionState.challenge.status = ReduxRequestStatus.init;
        challengeActionState.challenge.error = null;
        challengeActionState.validation.status = ReduxRequestStatus.init;
        challengeActionState.validation.error = null;
        challengeActionState.rateLimit = 0;
        if (challengeActionState.validation.data) {
          challengeActionState.validation.data.status = null;
        }
      }
    })
})

import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'core/redux/store';
import { ReduxRequestStatus } from 'core/types/redux/commonTypesRedux';

export const getSettingsReducer = (state: AppState) => state.settingsReducer;

export const getSettingsRequestStatus = (state: AppState) => getSettingsReducer(state).status;

export const getSettingsData = (state: AppState) => getSettingsReducer(state).data;

export const getSettingsError = (state: AppState) => getSettingsReducer(state).error;

export const getIsSettingsStatusInit = (state: AppState) => getSettingsRequestStatus(state) === ReduxRequestStatus.init;

export const getIsSettingsStatusLoading = (state: AppState) => getSettingsRequestStatus(state) === ReduxRequestStatus.loading;

export const getIsSettingsStatusError = (state: AppState) => getSettingsRequestStatus(state) === ReduxRequestStatus.error;

export const getIsSettingStatusInitOrLoading = (state: AppState) => getIsSettingsStatusInit(state) || getIsSettingsStatusLoading(state);

export const getEmailPatterns = createSelector(
  getSettingsData,
  (settingsData) => ({
    allowedEmails: settingsData?.allowedEmails ?? [],
    validEmails: settingsData?.validEmails ?? [],
  })
);

export const getPhonePatterns = createSelector(
  getSettingsData,
  (settingsData) => ({
    allowedPhones: settingsData?.allowedPhones ?? [],
    validPhones: settingsData?.validPhones ?? [],
  })
);

import { KeycloakConfig } from 'keycloak-js';

import { authClient } from 'core/auth/authClient';
import { authUserFailure, authUserStart, authUserSuccess } from 'core/redux/actions/authActions';
import { getReduxError } from 'utils/redux/commonUtilsRedux';
import { isNotOverrideError } from 'utils/auth/errorUtilsAuth';

import { AppDispatch } from 'core/redux/store';
import { AuthErrorMessages } from 'core/types/redux/authTypesRedux';

export const authenticateUser = (options: KeycloakConfig) => async (dispatch: AppDispatch) => {
  try {
    dispatch(authUserStart());
    if (!authClient.isKcClientCreated()) {
      authClient.createKcClient(options);
      authClient.initRefreshToken((err) => {
        const overriddenError = isNotOverrideError(err) ? err : new Error(AuthErrorMessages.refreshToken);
        
        dispatch(authUserFailure(getReduxError(overriddenError)));
      });

      const isAuthorized = await authClient.initKcClient();
      
      if (isAuthorized) {
        dispatch(authUserSuccess());
      } else {
        authClient.login();
      }
    }
  } catch (err) {
    const overriddenError = isNotOverrideError(err) ? err : new Error(AuthErrorMessages.getTokenOrCode);

    dispatch(authUserFailure(getReduxError(overriddenError)));

    throw err;
  }
}
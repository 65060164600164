import { AxiosError, isAxiosError } from 'axios';
import { isErrorWithMessage, isKeycloakError } from 'utils/common/typeGuardsUtilsCommon';
import {
  CLIENT_STATUS_CODE_ERROR,
  SERVER_RECAPTCHA_BROWSER_ERROR,
  SERVER_STATUS_CODE_ERROR,
  TIMEOUT_ERROR_CODE,
  TOO_MANY_REQUESTS_STATUS_CODE
} from 'core/constants/api-clients/commonConstantsApiClients';
import {
  GET_RECAPTCHA_TOKEN_ERROR,
  NO_RESPONSE_WHEN_RECAPTCHA_REQUESTED,
  NOTIFICATIONS_INCONSISTENCY,
  NOTIFICATIONS_SUBSCRIPTIONS_INCONSISTENCY,
  RECAPTCHA_TOKEN_IS_ABSENT,
  REFRESH_DATA_ERROR,
  REFRESH_DATA_SUCCESS
} from 'core/constants/redux/errorMessagesReduxConstants';

import {
  ErrorType,
  ErrorWithMessage,
  InternalErrorTypes,
  KeycloakErrorTypes,
  RecaptchaErrorTypes,
  UserContactInfoErrorTypes
} from 'core/types/redux/commonTypesRedux';
import { ProfileErrorData, ProfileErrorDataCodes } from 'core/types/api-clients/profileTypesApiClients';

export const getErrorTypeByAxiosError = (error: AxiosError<ProfileErrorData>) => {
  const { code, response } = error;
  
  if (code === TIMEOUT_ERROR_CODE) {
    return ErrorType.timeout;
  }
  
  if (!response?.status) {
    return ErrorType.unknown;
  }
  
  if (response.status === TOO_MANY_REQUESTS_STATUS_CODE) {
    return ErrorType.toManyRequests;
  }
  
  if (response.status === CLIENT_STATUS_CODE_ERROR && response.data.code === ProfileErrorDataCodes.errorDuplicateEmail) {
    return UserContactInfoErrorTypes.emailConflict;
  }
  
  if (response.status === CLIENT_STATUS_CODE_ERROR && response.data.code === ProfileErrorDataCodes.errorDuplicatePhone) {
    return UserContactInfoErrorTypes.phoneConflict;
  }
  
  if (response.status === SERVER_RECAPTCHA_BROWSER_ERROR) {
    return RecaptchaErrorTypes.recaptchaBrowserError;
  }
  
  return response.status >= SERVER_STATUS_CODE_ERROR ? ErrorType.server : ErrorType.client;
}

export const getErrorTypeByErrorMessage = (error: ErrorWithMessage) => {
  const { message } = error;
  
  if (message === REFRESH_DATA_SUCCESS) {
    return InternalErrorTypes.updateData;
  }
  
  if (message === REFRESH_DATA_ERROR) {
    return InternalErrorTypes.outOfDateData;
  }
  
  if (message === NOTIFICATIONS_INCONSISTENCY) {
    return InternalErrorTypes.notificationsInconsistency;
  }
  
  if (message === NOTIFICATIONS_SUBSCRIPTIONS_INCONSISTENCY) {
    return InternalErrorTypes.notificationsSubscriptionsInconsistency;
  }
  
  if (message === RECAPTCHA_TOKEN_IS_ABSENT) {
    return RecaptchaErrorTypes.recaptchaTokenAbsent;
  }
  
  if (message === NO_RESPONSE_WHEN_RECAPTCHA_REQUESTED) {
    return RecaptchaErrorTypes.noResponseWhenRecaptchaRequested;
  }
  
  if (message === GET_RECAPTCHA_TOKEN_ERROR) {
    return RecaptchaErrorTypes.getRecaptchaTokenError;
  }
  
  return ErrorType.unknown
}

export const getReduxError = (err: unknown) => {
  const serializedError = JSON.stringify(err);

  if (isKeycloakError(err)) {
    return {
      body: serializedError,
      message: err.error_description,
      errorType: KeycloakErrorTypes.authError
    };
  }
  
  if (isAxiosError(err)) {
    return {
      body: serializedError,
      message: err.response?.data.message || err.message,
      response: {
        code: err.code,
        data: err.response?.data,
        status: err.response?.status,
        statusText: err.response?.statusText
      },
      errorType: getErrorTypeByAxiosError(err)
    }
  }
  
  if (err instanceof Error || isErrorWithMessage(err)) {
    return {
      body: serializedError,
      message: err.message,
      errorType: getErrorTypeByErrorMessage(err)
    }
  }
  
  return {
    body: serializedError,
    errorType: ErrorType.unknown
  }
}

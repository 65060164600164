import { AxiosResponse, isAxiosError } from 'axios';

import { SERVER_STATUS_CODE_ERROR } from 'core/constants/api-clients/commonConstantsApiClients';
import { REFRESH_DATA_ERROR, REFRESH_DATA_SUCCESS } from 'core/constants/redux/errorMessagesReduxConstants';

export const withRefreshErrorHandling = async <T, U>(
  {
    patchMethod,
    getMethod,
    successGetCallback
  }: {
    patchMethod: () => Promise<AxiosResponse<T>>,
    getMethod: () => Promise<AxiosResponse<U>>,
    successGetCallback: (data: U) => void;
  }
) => {
  try {
    return await patchMethod();
  } catch (err) {
    if (isAxiosError(err) && err.response?.status && err.response?.status >= SERVER_STATUS_CODE_ERROR) {
      try {
        const { data } = await getMethod();
        
        successGetCallback(data);
      } catch (e) {
        throw new Error(REFRESH_DATA_ERROR);
      }
      throw new Error(REFRESH_DATA_SUCCESS);
    }
    throw err;
  }
}
import { getRecaptchaFailure, getRecaptchaStart, getRecaptchaSuccess } from 'core/redux/actions/recaptchaActions';
import { getRecaptchaToken } from 'utils/ui/recaptchaUtilsUi';
import { getReduxError } from 'utils/redux/commonUtilsRedux';

import { RECAPTCHA_TOKEN_IS_ABSENT } from 'core/constants/redux/errorMessagesReduxConstants';

import { AppDispatch } from 'core/redux/store';
import { RecaptchaActions } from 'core/types/recaptcha/actionsTypesRecaptcha';

export const getRecaptcha = () => (dispatch: AppDispatch) => {
  try {
    dispatch(getRecaptchaStart());
    
    if (!document.getElementById('recaptcha')) {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.id = 'recaptcha';
      script.async = true;
      script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6LdzYo0mAAAAAMznG6U7S9SAEcP664uXmzT--aLp';
      script.onload = async () => {
        const recaptchaToken = await getRecaptchaToken(RecaptchaActions.onload);
        
        if (recaptchaToken) {
          return dispatch(getRecaptchaSuccess());
        } else {
          const err = new Error(RECAPTCHA_TOKEN_IS_ABSENT);

          dispatch(getRecaptchaFailure(getReduxError(err)));
          throw err;
        }
      };
      script.onerror = (ev, source, lineno, colno, err) => {
        dispatch(getRecaptchaFailure(getReduxError(err)));
        
        throw err;
      };
      head?.appendChild(script);
    }
  } catch (err) {
    dispatch(getRecaptchaFailure(getReduxError(err)));
  }
};
import { createAction } from '@reduxjs/toolkit';
import { OTPConstants } from '../constants/OTPConstants';
import {
  CreateChallengeFailureData,
  CreateChallengeStartData,
  CreateChallengeSuccessData, OTPChallengeAction,
  ValidateChallengeSuccessData,
} from 'core/types/redux/otpTypesRedux';
import { ReduxError } from 'core/types/redux/commonTypesRedux';

export const createChallengeInitLoading = createAction<CreateChallengeStartData>(OTPConstants.CREATE_CHALLENGE_INIT_LOADING);
export const createChallengeLoading = createAction<CreateChallengeStartData>(OTPConstants.CREATE_CHALLENGE_LOADING);
export const createChallengeSuccess = createAction<CreateChallengeSuccessData>(OTPConstants.CREATE_CHALLENGE_SUCCESS);
export const createChallengeFailure = createAction<CreateChallengeFailureData>(OTPConstants.CREATE_CHALLENGE_FAILURE);

export const validateChallengeStart = createAction<OTPChallengeAction>(OTPConstants.VALIDATE_CHALLENGE_START);
export const validateChallengeSuccess = createAction<ValidateChallengeSuccessData>(OTPConstants.VALIDATE_CHALLENGE_SUCCESS);
export const validateChallengeFailure = createAction<{ err: ReduxError, challengeAction: OTPChallengeAction }>(OTPConstants.VALIDATE_CHALLENGE_FAILURE);

export const resetChallengeStatus = createAction<OTPChallengeAction>(OTPConstants.RESET_CHALLENGE_STATUS);

import { sleep } from 'utils/common/promiseUtilsCommon';

export const withRepeatRequest = async <T>({
  func,
  repeatCount,
  delay,
  errorMessage,
}: {
  func: () => Promise<T>;
  repeatCount: number;
  delay: number;
  errorMessage: string;
}) => {
  let counter = repeatCount;
  let error: unknown | null = null;
  do {
    try {
      return await func();
    } catch (err) {
      error = err;
      counter = counter - 1;
      await sleep(delay);
    }
  } while (counter > 0);

  if (error) {
    throw error;
  } else {
    throw new Error(errorMessage);
  }
};

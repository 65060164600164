import { isNotNullish, isNotUndefined } from 'utils/common/typeGuardsUtilsCommon';
import {
  CurrentUserPatchData,
} from 'core/types/api-clients/profileTypesApiClients';
import { CurrentUserReduxData } from 'core/types/redux/profileTypesRedux';

export const getSecondaryEmailVerifiedValueByPatchData = (
  secondaryEmail: string | null | undefined,
  patchSecondaryEmailVerified: boolean | null | undefined,
  stateSecondaryEmailVerified: boolean | null,
  withOtp: boolean,
) => {
  if (patchSecondaryEmailVerified === null) {
    return null;
  }
  
  if (isNotUndefined(patchSecondaryEmailVerified) || isNotNullish(secondaryEmail)) {
    return withOtp;
  }
  
  return stateSecondaryEmailVerified
};

export const getSubscriptionsValueByPatchData = (
  { patchData, stateData, withOtp }:
  { patchData: CurrentUserPatchData, stateData: CurrentUserReduxData, withOtp: boolean }
) => {
  const isSecondaryEmailNullAndOnePointOfNotificationsOptIn =
    patchData.contactInfo?.secondaryEmail === null
    && stateData.contactInfo.secondaryEmailNotificationsOptIn
    && !stateData.contactInfo.phoneNotificationsOptIn;
  const isPhoneNumberChangedWithoutOtpAndOnePointOfNotificationsOptIn =
    isNotNullish(patchData.contactInfo?.phone)
    && stateData.contactInfo.phoneNotificationsOptIn
    && !stateData.contactInfo.secondaryEmailNotificationsOptIn
    && !stateData.contactInfo.emailNotificationsOptIn
    && !withOtp;
  
  return isSecondaryEmailNullAndOnePointOfNotificationsOptIn || isPhoneNumberChangedWithoutOtpAndOnePointOfNotificationsOptIn
    ? []
    : stateData.subscriptions;
}

export const optimisticallyUpdateCurrentUserData = (stateData: CurrentUserReduxData | null, patchDataWithOtp: CurrentUserPatchData) => {
  if (!stateData) {
    return stateData;
  }
  
  const { otpChallengeID, otpCode, ...patchData } = patchDataWithOtp;
  const withOtp = !!otpChallengeID;
  
  return {
    ...stateData,
    ...patchData,
    contactInfo: {
      ...stateData.contactInfo,
      phoneVerified: isNotUndefined(patchData.contactInfo?.phone) ? withOtp : stateData.contactInfo.phoneVerified,
      emailVerified: isNotUndefined(patchData.contactInfo?.email) ? withOtp : stateData.contactInfo.emailVerified,
      secondaryEmailVerified: getSecondaryEmailVerifiedValueByPatchData(
        patchData.contactInfo?.secondaryEmail,
        patchData.contactInfo?.secondaryEmailVerified,
        stateData.contactInfo?.secondaryEmailVerified,
        withOtp,
      ),
      phoneNotificationsOptIn: isNotUndefined(patchData.contactInfo?.phone) && !withOtp ? false : stateData.contactInfo.phoneNotificationsOptIn,
      secondaryEmailNotificationsOptIn: patchData.contactInfo?.secondaryEmail === null ? false : stateData.contactInfo.secondaryEmailNotificationsOptIn,
      ...patchData.contactInfo,
      // We should optimistically update fields since they depend on other fields but not overwrite them by patch data
    },
    subscriptions: getSubscriptionsValueByPatchData({ patchData, stateData, withOtp }),
  }
};

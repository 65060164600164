export enum ProfileConstants {
  GET_CURRENT_USER_START = 'profile/getCurrentUserStart',
  GET_CURRENT_USER_SUCCESS = 'profile/getCurrentUserSuccess',
  GET_CURRENT_USER_FAILURE = 'profile/getCurrentUserFailure',

  PATCH_CURRENT_USER_START = 'profile/patchCurrentUserStart',
  PATCH_CURRENT_USER_SUCCESS = 'profile/patchCurrentUserSuccess',
  PATCH_CURRENT_USER_FAILURE = 'profile/patchCurrentUserFailure',
  UPDATE_CURRENT_USER = 'profile/updateCurrentUser',
  
  GET_CURRENT_USER_NOTIFICATIONS_START = 'profile/getCurrentUserNotificationsStart',
  GET_CURRENT_USER_NOTIFICATIONS_SUCCESS = 'profile/getCurrentUserNotificationsSuccess',
  GET_CURRENT_USER_NOTIFICATIONS_FAILURE = 'profile/getCurrentUserNotificationsFailure',
  
  PATCH_CURRENT_USER_NOTIFICATIONS_START = 'profile/patchCurrentUserNotificationsStart',
  PATCH_CURRENT_USER_NOTIFICATIONS_SUCCESS = 'profile/patchCurrentUserNotificationsSuccess',
  PATCH_CURRENT_USER_NOTIFICATIONS_FAILURE = 'profile/patchCurrentUserNotificationsFailure',
  RESET_CURRENT_USER_NOTIFICATIONS_ERROR = 'profile/resetCurrentUserNotificationsError',
  
  PATCH_CURRENT_USER_SUBSCRIPTIONS_START = 'profile/patchCurrentUserSubscriptionsStart',
  PATCH_CURRENT_USER_SUBSCRIPTIONS_SUCCESS = 'profile/patchCurrentUserSubscriptionsSuccess',
  PATCH_CURRENT_USER_SUBSCRIPTIONS_FAILURE = 'profile/patchCurrentUserSubscriptionsFailure',
  RESET_CURRENT_USER_SUBSCRIPTIONS_ERROR = 'profile/resetCurrentUserSubscriptionsError',
  
  DELETE_CURRENT_USER_START = 'profile/deleteCurrentUserStart',
  DELETE_CURRENT_USER_SUCCESS = 'profile/deleteCurrentUserSuccess',
  DELETE_CURRENT_USER_FAILURE = 'profile/deleteCurrentUserFailure',
  
  GET_PROFILE_SUPPORT_SUBJECTS_START = 'profile/getProfileSupportSubjectsStart',
  GET_PROFILE_SUPPORT_SUBJECTS_SUCCESS = 'profile/getProfileSupportSubjectsSuccess',
  GET_PROFILE_SUPPORT_SUBJECTS_FAILURE = 'profile/getProfileSupportSubjectsFailure',

  POST_PROFILE_SUPPORT_REQUEST_START = 'profile/postProfileSupportRequestStart',
  POST_PROFILE_SUPPORT_REQUEST_SUCCESS = 'profile/postProfileSupportRequestSuccess',
  POST_PROFILE_SUPPORT_REQUEST_FAILURE = 'profile/postProfileSupportRequestFailure',
}
import { getReduxError } from 'utils/redux/commonUtilsRedux';

export type ReduxError = ReturnType<typeof getReduxError>

export interface ErrorWithMessage {
  message: string;
}

export enum ReduxRequestStatus {
  init = 'init',
  initLoading = 'init-loading',
  loading = 'loading',
  success = 'success',
  error = 'error',
  initError = 'init-error'
}

export enum InternalErrorTypes {
  updateData = 'updateData',
  outOfDateData = 'outOfDateData',
  notificationsInconsistency = 'notificationsInconsistency',
  notificationsSubscriptionsInconsistency = 'notificationsSubscriptionsInconsistency',
}

export enum RecaptchaErrorTypes {
  recaptchaTokenAbsent = 'recaptchaTokenAbsent',
  recaptchaBrowserError = 'recaptchaBrowserError',
  noResponseWhenRecaptchaRequested = 'noResponseWhenRecaptchaRequested',
  getRecaptchaTokenError = 'getRecaptchaTokenError',
}

export enum KeycloakErrorTypes {
  authError = 'authError',
}

export enum UserContactInfoErrorTypes {
  emailConflict = 'emailConflict',
  phoneConflict = 'phoneConflict'
}

export enum ErrorType {
  client = 'client',
  server = 'server',
  toManyRequests = 'tooManyRequests',
  timeout = 'timeout',
  unknown = 'unknown',
}

import Keycloak, { KeycloakConfig } from 'keycloak-js';

import { getPromiseWithTimeoutRejection } from 'utils/common/promiseUtilsCommon';
import { UPDATE_TOKEN_MIN_VALIDITY } from 'core/constants/auth/clientConstantsAuth';
import { TIMEOUT_LIMIT } from 'core/constants/api-clients/commonConstantsApiClients';

import { OnLoadAuthParam, PkceAuthMethod } from 'core/constants/auth/realms';
import { ApiClientErrors } from 'core/types/api-clients/commonTypesApiClients';

export const getAuthClient = () => {
  let keycloakClient: Keycloak;
  
  return {
    createKcClient: (initOptions:  KeycloakConfig | string) => {
      keycloakClient = new Keycloak(initOptions);
    },

    initKcClient: () => {
      const keycloakPromise = keycloakClient.init({
        onLoad: OnLoadAuthParam.LOGIN_REQUIRED,
        checkLoginIframe: false,
        pkceMethod: PkceAuthMethod.S256,
        enableLogging: true,
      });
      
        return getPromiseWithTimeoutRejection(keycloakPromise, TIMEOUT_LIMIT, new Error(ApiClientErrors.timeout))
    },
    
    initRefreshToken: (errorCallback: (err: unknown) => void) => {
      keycloakClient.onTokenExpired = async () => {
        try {
          await keycloakClient.updateToken(UPDATE_TOKEN_MIN_VALIDITY);

        } catch (err) {
          errorCallback(err);
        }
      }
    },
    
    logout: () => keycloakClient.logout(),
    
    login: () => keycloakClient.login(),
    
    refreshToken: () => keycloakClient.updateToken(UPDATE_TOKEN_MIN_VALIDITY),
    
    getToken: () => `Bearer ${keycloakClient.token}`,

    isKcClientCreated: () => keycloakClient !== undefined,
  }
}

export const authClient = getAuthClient();
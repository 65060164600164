import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { cloneApiClient } from 'utils/api-clients/commonUtilsApiClients';
import { authClient } from 'core/auth/authClient';
import { AUTH_ERROR_CODE } from 'core/constants/api-clients/commonConstantsApiClients';



export const decorateKeycloakRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (config.headers) {
    config.headers.Authorization = authClient.getToken();
  }
  
  return config;
}

export const handleKeycloakError = async (error: unknown): Promise<unknown> => {
  if (axios.isAxiosError(error)) {
    if (error?.response?.status === AUTH_ERROR_CODE) {
      await authClient.refreshToken();
      
      if (error.config) {
        return axios.request(error.config);
      }
    }
  }
  throw error;
}

export const withKeycloak = (client: AxiosInstance): AxiosInstance => {
  const clonedApiClient = cloneApiClient(client);
  clonedApiClient.interceptors.request.use(decorateKeycloakRequest);
  clonedApiClient.interceptors.response.use(data => data, handleKeycloakError);
  return clonedApiClient;
};
